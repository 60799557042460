.side-menu {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -250px;
  transition: left 0.3s ease;
  z-index: 100;
}
.side-menu.open {
  left: 0;
}
.close-btn {
  margin-bottom: 10px;
}

.dark-mode {
  background-color: #1f1c2b; 
  color: white; 
}

.light-mode {
  background-color: #f0f0f0; 
  color: black;
}