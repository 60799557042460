.shimmer {
    background-image: linear-gradient(to right, #f0f0f0 0%, #c0c0c0 25%, #f0f0f0 50%, #c0c0c0 75%, #f0f0f0 100%);
    background-repeat: no-repeat;
    background-size: 400% auto;
    animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
    0% {
        background-position: 0% center;
    }
    100% {
        background-position: 100% center;
    }
}